import * as W from '@wix/wix-bi-logger';
import { utils } from './utils';
import VIEW_MODE from 'pro-gallery/dist/src/utils/constants/viewMode';

export default class BiLogger {
  constructor(biContext, galleryId, viewMode, isStore) {
    const wixBiArgs = {
      defaultEventArgs: {},
      defaultErrorArgs: {},
      adapter: 'photo-ugc',
    };
    this.biLogger = W && W.BI ? new W.BI.Logger(wixBiArgs) : false;
    this.galleryLogData = {
      ...biContext,
      src: 65, //predefined wix-albums source
      galleryType: isStore ? 'store' : 'progallery',
    };

    this.viewMode = viewMode;
    this.galleryId = galleryId;

    this.OOIbiEvents = {
      share: {
        evid: 500,
      },
      love: {
        evid: 501,
      },
      download: {
        evid: 502,
      },
      gallery_rendered: {
        evid: 508,
      },
      item_clicked: {
        evid: 520,
      },
      item_expanded: {
        evid: 521,
      },
      buyNowClick: {
        evid: 800,
      },
      // info: {
      //   evid: 503,
      // },
      // pro_gallery_load_error: {
      //   evid: 509,
      // },
      // pro_gallery_loaded: {
      //   evid: 510,
      // },
      // pro_gallery_error: {
      //   evid: 512,
      // },
      // pro_gallery_load_uou: {
      //   evid: 304,
      // },
      // share_group: {
      //   evid: 601,
      // },
      // storeSetUpTab: {
      //   evid: 702,
      // },
      // expandSettings: {
      //   evid: 703,
      // },
      // expandSettingsDone: {
      //   evid: 704,
      // },
      // watermarkSettings: {
      //   evid: 705,
      // },
      // watermarkEnable: {
      //   evid: 706,
      // },
      // cartSettings: {
      //   evid: 708,
      // },
      // cartSettingsDesignTab: {
      //   evid: 709,
      // },
      // openStoreDashboard: {
      //   evid: 727,
      // },
      // saveBusinessInfo: {
      //   evid: 728,
      // },
      // artStore_startNow: {
      //   evid: 729,
      // },
      // artStore_chooseSellingMethod: {
      //   evid: 730,
      // },
    };

    this.trackBi = this.trackBi.bind(this);
    this.updateViewMode = this.updateViewMode.bind(this);
    this.updateGalleryId = this.updateGalleryId.bind(this);
  }

  updateViewMode(updateViewMode) {
    this.viewMode = updateViewMode;
  }

  updateGalleryId(updateGalleryId) {
    this.galleryId = updateGalleryId;
  }

  trackBi(eventName, params = {}, callback) {
    const biEvent = this.OOIbiEvents[eventName];
    if (!biEvent) {
      console.error('TRACK EVENT ERROR - No such bi event ' + eventName);
      return false;
    }

    const evid = biEvent.evid;
    if (
      (this.isUserOfUserEvent(evid) && this.viewMode !== VIEW_MODE.SITE) ||
      utils.isDev() ||
      utils.isTest()
    ) {
      return;
    }

    try {
      const logData = Object.assign(
        {},
        this.galleryLogData,
        { gallery_id: this.galleryId },
        params,
      );
      this.biLog(evid, logData, callback);
    } catch (e) {
      console.warn('BI LOGGER ERROR!', e);
    }
  }

  biLog(evid, params, callback) {
    if (!this.biLogger) {
      console.warn('no biLogger, cannot log event');
      return;
    }
    if (typeof evid === 'undefined') {
      console.warn('evid is missing, cannot log event', evid, params);
      return;
    }
    if (typeof params === 'undefined') {
      console.warn('params are missing, cannot log event', evid, params);
      return;
    }

    const logData = Object.assign({ evid }, params);

    const adapter = this.getAdapter(evid);
    if (adapter !== null) {
      Object.assign(logData, { adapter });
    }
    this.biLogger.log(logData, callback);
  }

  getAdapter(evid) {
    let adapter;
    if (this.isUserEvent(evid)) {
      adapter = 'photo-users';
    } else if (this.isUserOfUserEvent(evid)) {
      adapter = 'photo-ugc';
    } else {
      console.error('evid', evid, 'is not user, or uou event');
    }
    return adapter;
  }

  isInRange(val, min, max) {
    return val >= min && val < max;
  }

  isUserEvent(evid) {
    return !this.isUserOfUserEvent(evid);
  }

  isUserOfUserEvent(evid) {
    return this.isInRange(evid, 500, 605) || evid === 304 || evid === 800;
  }
}
