import EVENTS from 'pro-gallery/dist/src/utils/constants/events';
import { utils } from '../../utils/utils';

export default class EventHandler {
  constructor(galleryWrapper, props) {
    this.galleryWrapper = galleryWrapper;
    this.galleryWrapperProps = props;

    this.reportedBiGalleryRendered = false;

    this.handleEvent = this.handleEvent.bind(this);
    this.componentDidLayout = this.componentDidLayout.bind(this);
    this.setZeroItems = this.setZeroItems.bind(this);
    this.initComponentDidLayout = this.initComponentDidLayout.bind(this);
  }

  initComponentDidLayout() {
    const { registerToComponentDidLayout } = this.galleryWrapperProps;
    registerToComponentDidLayout(this.componentDidLayout);
  }

  handleEvent(eventName, eventData) {
    switch (eventName) {
      case EVENTS.ITEM_CREATED:
        this.onItemCreated();
        break;
      case EVENTS.ITEM_ACTION_TRIGGERED:
        this.galleryWrapper.itemsHelper.onItemActionTriggered(
          eventData,
          this.galleryWrapper.siteHelper.getPGStyles(),
        );
        break;
      case EVENTS.CURRENT_ITEM_CHANGED:
        this.galleryWrapper.itemsHelper.onCurrentItemChanged(eventData);
        break;
      case EVENTS.GALLERY_CHANGE:
        this.galleryWrapper.siteHelper.handleNewGalleryStructure(eventData);
        break;
      case EVENTS.NEED_MORE_ITEMS:
        this.galleryWrapper.itemsHelper.getMoreItems(eventData);
        break;
      case EVENTS.SHARE_BUTTON_CLICKED:
        this.galleryWrapper.itemsHelper.handleItemActions('share', eventData);
        this.galleryWrapper.logHelper.reportBiEvent('share', eventData);
        break;
      case EVENTS.TEXT_DOWNLOAD_BUTTON_CLICKED:
        this.galleryWrapper.itemsHelper.handleItemActions(
          'downloadTextItem',
          eventData,
        );
        this.galleryWrapper.logHelper.reportBiEvent(
          'downloadTextItem',
          eventData,
        );
        break;
      case EVENTS.LOVE_BUTTON_CLICKED:
        this.galleryWrapper.itemsHelper.handleItemActions(
          'postLoveActivity',
          eventData,
        );
        if (
          !this.galleryWrapper.itemsHelper
            .getItemActions()
            .isLoved(eventData.id)
        ) {
          //check isLoved before toggleLove action
          //report bi event only if loved, not if unloved
          this.galleryWrapper.logHelper.reportBiEvent('love', eventData);
        }
        this.galleryWrapper.itemsHelper.handleItemActions(
          'toggleLove',
          eventData,
        );
        break;
      case EVENTS.ITEM_CLICKED:
        this.galleryWrapper.logHelper.reportBiEvent('onItemClicked', eventData);
        break;
      case EVENTS.DOWNLOAD_BUTTON_CLICKED:
        this.galleryWrapper.logHelper.reportBiEvent(
          'onDownloadButtonClicked',
          eventData,
        );
        break;
      case EVENTS.BUY_NOW_CLICKED:
        this.galleryWrapper.logHelper.reportBiEvent(
          'onBuyNowClicked',
          eventData,
        );
        break;
      case EVENTS.THUMBNAIL_CLICKED:
        this.galleryWrapper.logHelper.reportBiEvent(
          'onThumbnailClicked',
          eventData,
        );
        break;
      default:
        if (utils.isVerbose()) {
          console.log(eventName, 'is not handled in handleEvent function');
        }
    }
  }

  componentDidLayout() {
    this.alreadyDidLayout = true;
    if (this.firstItemCreated || this.zeroItems) {
      this.galleryWrapper.logHelper.onAppLoaded();
      this.reportedBiGalleryRenderedIfNeeded();
    }
  }

  onItemCreated() {
    this.firstItemCreated = true;
    if (this.alreadyDidLayout) {
      this.galleryWrapper.logHelper.onAppLoaded();
      this.reportedBiGalleryRenderedIfNeeded();
    }
  }

  reportedBiGalleryRenderedIfNeeded() {
    //should report only once!
    if (!this.reportedBiGalleryRendered) {
      this.reportedBiGalleryRendered = true;
      this.galleryWrapper.logHelper.reportBiEvent('gallery_rendered');
    }
  }

  setZeroItems() {
    this.zeroItems = true;
  }
}
